const DeliveryAddressTemplate = {
  firstName: 'Sample',
  lastName: 'Consumer',
  line1: '123 Placeholder',
  line2: 'Apt 1',
  town: 'Somewhereville',

  postalCode: '87123',
  country: {
    isocode: 'US',
  },
};
//Ecommerce locales that require regions
const DefaultRegions = {
  JP: 'JP-01',
};
const DistrictRegions = ['GB'];

const getRegion = function (location, countryIso) {
  if (DistrictRegions.includes(countryIso)) {
    return {
      name: location?.regionCode,
    };
  }
  if (DefaultRegions[countryIso] && !location?.regionCode) {
    return {
      isocode: DefaultRegions[countryIso],
    };
  }
  return {
    isocode: location?.regionCode,
  };
};
const populateAddress = async function (countryCode, location, deliveryAddress) {
  const countryIso = countryCode?.toUpperCase();
  let address = {
    ...DeliveryAddressTemplate,
    line1: deliveryAddress?.line1 || DeliveryAddressTemplate.line1,
    line2: deliveryAddress?.line2 || DeliveryAddressTemplate.line2,
    town: deliveryAddress?.townCity || DeliveryAddressTemplate.town,
    postalCode: deliveryAddress?.postcode || location?.postcode,
    country: {
      isocode: countryIso,
    },
  };
  if (deliveryAddress?.regionIso) {
    address.region = {isocode: deliveryAddress.regionIso};
  } else {
    const region = getRegion(location, countryIso);
    if (region?.isocode || region?.name) {
      address.region = region;
    }
  }

  return address;
};
const getFormattedAddress = (formObject) => {
  const formattedAddress = [
    formObject.line1,
    formObject.line2,
    formObject.townCity,
    formObject.regionIso,
    formObject.postcode,
  ]
    .filter(Boolean)
    .join(',');

  return encodeURIComponent(formattedAddress);
};
function getRegionMap(regions) {
  const regionMap = new Map();
  regions.forEach((item) => {
    regionMap.set(item.isocode, item);
  });
  return regionMap;
}
function normalisePostalCode(postcode) {
  return postcode?.replace(/\s/g, '').toUpperCase();
}
export {populateAddress, DistrictRegions, getFormattedAddress, getRegionMap, normalisePostalCode};
